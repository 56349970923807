<template>
  <div>
    <!--    <GameHistoryWidgets :widgets-data="summaryData" />-->
    <b-card>
      <b-row class="mb-3">
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${filteredList.length})` }}
          </h6>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="filterPlayerUsername"
                  type="search"
                  :placeholder="`${$t('fields.search')}... (${$t(
                    'user.username'
                  )})`"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group class="ml-md-2">
                <b-form-input
                  v-model="filterRoundId"
                  type="search"
                  :placeholder="`${$t('fields.search')}... (${$t(
                    'games.room_id'
                  )})`"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="gameTransactions.data"
        :fields="fields"
        :busy="isFetchingTransactions"
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ `${data.value}` }}
        </template>
        <template #cell(username)="data">
          {{ data.value }}
        </template>
        <template #cell(txnId)="data">
          {{ data.value || 'Coming..' }}
        </template>
        <template #cell(roundId)="data">
          {{ data.value || 'Coming..' }}
        </template>
        <template #cell(winAmount)="data">
          <b-badge v-if="Number(data.value) > 0.0" variant="primary">
            {{ data.value | currency }}
          </b-badge>
          <span v-else>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(gameName)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(gameType)="data">
          <b-badge variant="light">
            {{ data.value || 'Slot' }}
          </b-badge>
        </template>
        <template #cell(has_details)="data">
          <b-button
            v-if="data.value === 'url' || data.value === 'json'"
            size="sm"
            @click="fetchGameBetDetail(data.item.id, data.value)"
          >
            ดู
          </b-button>
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.value === 1" variant="success">
            {{ $t('utils.status.finished') }}
          </b-badge>
          <b-badge v-else-if="data.value === 0" variant="warning">
            {{ $t('utils.status.bet') }}
          </b-badge>
          <b-badge v-else-if="data.value === 2" variant="danger">
            {{ $t('utils.status.canceled') }}
          </b-badge>
          <b-badge v-else-if="data.value === 3" variant="danger">
            VOID
          </b-badge>
          <b-badge v-else-if="data.value === 4" variant="danger">
            INVALID
          </b-badge>
          <b-badge v-else-if="data.value === 5" variant="danger">
            BONUS
          </b-badge>
          <b-badge v-else-if="data.value === 6" variant="danger">
            JACKPOT
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <PaginationInput
        :per-page="limit"
        :total="total"
        @update="(val) => (page = val)"
      />
    </b-card>
    <!-- sbo modal -->
    <b-modal id="sbo-modal" size="xl">
      <b-table
        :items="filteredSBOList"
        :fields="fieldsSBO"
        responsive
        show-empty
      >
        <template #cell(information)="data">
          {{ data.item.information.username }} <br />
          <span class="text-primary font-weight-bold">{{
            data.item.information.refNo
          }}</span>
          <br />
          {{ data.item.information.date | date }} <br />
          {{ data.item.information.time | time }}
        </template>
        <template #cell(selection)="data">
          <div
            v-for="(item, index) in data.item.selection"
            :key="index"
            class="font-weight-bold"
            ><span class="text-danger">{{ item.betOption }} </span>
            <span class="text-muted">{{ formatNumber(item.hdp) }}</span>
            <span class="text-danger"> @ </span>
            <span class="text-muted"> {{ formatNumber(item.odds) }}</span>
            <br />
            <span class="text-primary">{{ item.sportType }}</span>
            /
            <span class="text-primary">{{ item.marketType }}</span>
            <br />
            {{ item.match }}
            <br />
            <span class="text-warning">{{ item.league }} </span>
            <span class="text-primary"> @ </span>
            <span class="text-warning">
              {{ item.date | date }}
            </span>
            <hr />
          </div>
        </template>
        <template #cell(stakeActualStake)="data">
          {{ formatNumber(data.item.stakeActualStake.stake) }} <br />
          {{ formatNumber(data.item.stakeActualStake.actualStake) }}
        </template>

        <template #cell(status)="data">
          <div v-for="(item, index) in data.item.status" :key="index"
            ><span :class="getStatusColor(item.status)">{{ item.status }}</span>
            <br />
            HT:{{ item.htScore }}
            <br />
            FT:{{ item.ftScore }}
            <br />
            <hr />
          </div>
        </template>

        <template #cell(odds)="data">
          <span class="text-success">{{ formatNumber(data.item.odds) }}</span>
        </template>
        <template #cell(memberWinLose)="data">
          <span class="text-success">{{
            formatNumber(data.item.memberWinLose)
          }}</span>
        </template>
      </b-table>
    </b-modal>
    <!-- end sbo modal -->
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ProviderGameHistory',
  components: {
    // FilterAgentsDropdown: () => import('../../agents/filter-agents-dropdown'),
  },
  props: {
    gameSlug: {
      type: [String, Number],
      required: true,
      default: undefined,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedMasterId: {
      type: String,
      default: '',
    },
    selectedAgentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onSearchTimeout: null,
      summaryData: [],
      totalRows: 0,
      selectedLimit: 50,
      page: 1,
      filterAgent: null,
      filterRoundId: '',
      filterPlayerUsername: '',
      isLoading: true,
      sboList: [],
      filteredList: [],
      fields: [
        {
          key: 'id',
          label: '#',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agent_name',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'username',
          label: `${this.$t('user.username')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'txnId',
          label: 'เลขธุรกรรม',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'roundId',
          label: 'รอบที่',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'betAmount',
          label: `${this.$t('games.bet_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'winAmount',
          label: `${this.$t('games.win_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'gameName',
          label: 'ชื่อเกม',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'gameType',
          label: 'ชนิด',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'has_details',
          label: '-',
          class: 'text-center',
          thStyle: {
            minWidth: '50px',
          },
        },
        {
          key: 'status',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],

      fieldsSBO: [
        {
          key: 'information',
          label: 'Information',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass: 'text-center align-middle border border-secondary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'selection',
          label: 'Selection',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass: 'text-right align-middle border border-secondary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'currency',
          label: 'Currency',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass: 'text-center align-middle border border-secondary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'stakeActualStake',
          label: 'Stake Actual Stake',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass:
            'text-center align-middle border border-secondary text-primary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'odds',
          label: 'Odds',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass: 'text-center align-middle border border-secondary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'status',
          label: 'Status',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass: 'text-center align-middle border border-secondary',
          thClass: 'bg-primary text-white border border-secondary',
        },
        {
          key: 'memberWinLose',
          label: 'Member Winlose',
          thStyle: {
            minWidth: '100px',
            textAlign: 'center',
          },
          tdClass:
            'text-center align-middle border border-secondary text-danger',
          thClass: 'bg-primary text-white border border-secondary',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isMaster', 'userInfo', 'isOwner']),
    ...mapState({
      gameTransactions: (state) => state.game.gameTransactions,
      isFetchingTransactions: (state) => state.game.isFetchingTransactions,
    }),
    agentId() {
      return this.userInfo.agentId
    },
    limit() {
      return this.gameTransactions.totalItemOnPage
    },
    total() {
      return this.gameTransactions.totalItems
    },
    filteredSBOList() {
      return this.sboList || []
    },
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    gameSlug(val) {
      if (val) {
        this.fetchData()
      }
    },
    page(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    filterAgent() {
      this.fetchData()
    },
    filterRoundId() {
      this.delaySearch()
    },
    filterPlayerUsername() {
      this.delaySearch()
    },
    selectedMasterId() {
      this.fetchData()
    },
    selectedAgentId() {
      this.fetchData()
    },
  },
  methods: {
    ...mapActions(['fetchTransaction', 'fetchBetDetail']),
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    fetchData() {
      this.fetchGameTransaction()
    },
    fetchGameTransaction() {
      const date = dayjs(this.selectedDate || new Date()).format('YYYY-MM-DD')
      this.summaryData = [
        {
          icon: 'activity',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: '0 ครั้ง',
        },
        {
          icon: 'dollar-sign',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: '0 บาท',
        },
        {
          icon: 'coffee',
          name: `${this.$t('games.win')}`,
          value: '0 บาท',
        },
      ]

      this.fetchTransaction({
        id: this.gameSlug,
        data: {
          page: this.page,
          limit: this.selectedLimit,
          roundId: this.filterRoundId || '',
          agentId: this.selectedAgentId,
          masterId: this.selectedMasterId,
          username: this.filterPlayerUsername,
          date,
        },
      })
    },
    async fetchGameBetDetail(betId, detailType) {
      const data = await this.fetchBetDetail({
        gameId: this.gameSlug,
        betId,
      })
      if (detailType === 'url') {
        window.open(data.url, '_blank')
      } else if (detailType === 'json') {
        this.$bvModal.show('sbo-modal')
        this.sboList = JSON.parse(data.url)
      }
    },
    getStatusColor(status) {
      let textClass = ''
      switch (status) {
        case 'lose':
          textClass = 'text-danger'
          break
        case 'draw':
          textClass = 'text-dark'
          break
        default:
          textClass = 'text-primary'
          break
      }
      return textClass
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2)
    },
  },
}
</script>
